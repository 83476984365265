// Card
$card-border-radius: 4px;

// Misc
$grey-line: 1px solid $color-grey-light;
$zindex-lift-top: 1005;
$nav-height-top: 50px;
$nav-height-bottom: 50px;

// Shadow
$shadow-default: 0 3px 6px $color-shadow;
$shadow-light: 0 0 6px $color-shadow;
$shadow-top: 0 -10px 20px $color-shadow;
$shadow-focus: 0 0 0 3px $color-button-focus;

// Fade overflowing content to white.
// Requirements: $height ~= line-height and parent to have position: relative & overflow: hidden
@mixin fade-ellipsis($height) {
  background: linear-gradient(90deg, $color-transparent, $color-white 85%);
  bottom: 0;
  content: "";
  height: $height;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: right;
  width: 40%;
}

:export {
  cardBorderRadius: $card-border-radius;
  greyLine: $grey-line;
  zindexLiftTop: $zindex-lift-top;
  navHeightTop: $nav-height-top;
  navHeightBottom: $nav-height-bottom;
  shadowDefault: $shadow-default;
  shadowFocus: $shadow-focus;
  shadowLight: $shadow-light;
  shadowTop: $shadow-top;
}
